import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import Image from 'next/image'

import { Column, Section, Columns, Rows } from '@/components/layout'
import { Text, ButtonGroup } from '@/components/common'
import TryFreeCta from '@/components/TryFreeCta'
import PricingCta from '@/components/PricingCta'
import paperPileImg from '@/assets/home/paper-pile.png'

import styles from './HomePageStats.module.scss'

const getMessageId = (id) => `pages.home.stats.${id}`

const data = [
  { id: 'waste', icon: 'stopwatch' },
  { id: 'efficiency', icon: 'search' },
  { id: 'protection', icon: 'lifepreserver' },
]

export default function HomePageStats({ replaceCtasWith }) {
  return (
    <Section bgBrand className={styles.section} wrapperClassName={styles.sectionWrapper}>
      <Columns className={styles.columns} gap={24}>
        <Column className={styles.statsColumn} size={75}>
          <Rows gap={32}>
            <Columns gap={24} wrap>
              {data.map(({ id, icon }) => (
                <Column key={id} className={cx(styles.statGroup, styles[icon])} gap={0}>
                  <Text bold className={styles.stat} element="div" onTint>
                    <FormattedMessage id={getMessageId(`${id}.stat`)} />
                    <Text messageId={getMessageId(`${id}.unit`)} normal onTint size="xlarge" type="display" />
                  </Text>
                  <Text bold brandSecondary className={styles.statDetails} messageId={getMessageId(`${id}.details`)} />
                </Column>
              ))}
            </Columns>
            <Rows center className={styles.statCallout} textCenter>
              <Rows gap={8}>
                <Text bold element="div" messageId={getMessageId('callout.title')} onTint size="medium" type="display" />
                <Text bold color="tint-brand-secondary" element="div" messageId={getMessageId('callout.subtitle')} size="small" type="display" />
              </Rows>
              { replaceCtasWith ||
              <ButtonGroup>
                <PricingCta messageId="cta.seePlansAndPricing" size="xlarge" variant="secondary" />
                <TryFreeCta />
              </ButtonGroup> }
            </Rows>
          </Rows>
        </Column>

        <Column className={styles.imageColumn} size={25}>
          <Image
            alt="paper-pile"
            className="hidden-mobile"
            src={paperPileImg}
          />
        </Column>
      </Columns>
    </Section>
  )
}

HomePageStats.propTypes = {
  replaceCtasWith: PropTypes.element,
}
