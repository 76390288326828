import React from 'react'
import PropTypes from 'prop-types'

import TryFreeCta from '@/components/TryFreeCta'
import BuyNowCta from '@/components/BuyNowCta'
import { ButtonGroup } from '@/components/common'

const BuyTry = ({
  canTry = true,
  buyCtaMessageId = 'cta.buyNow',
  size = 'xlarge',
  noOutline,
  promocode,
  testIdPrefix,
  buySku,
  trySku,
  tryCtaMessageId = 'cta.tryNeatFree',
  ...rest
}) => (
  <ButtonGroup {...rest}>
    <BuyNowCta
      data-testid={testIdPrefix && `${testIdPrefix}-buy-now`}
      messageId={buyCtaMessageId}
      promocode={promocode}
      size={size}
      sku={buySku}
      style={noOutline && { border: '0' }}
    />
    {canTry && <TryFreeCta data-testid={testIdPrefix && `${testIdPrefix}-try-neat-free`} messageId={tryCtaMessageId} size={size} sku={trySku} />}
  </ButtonGroup>
)

BuyTry.propTypes = {
  canTry: PropTypes.bool,
  buyCtaMessageId: PropTypes.string,
  size: PropTypes.string,
  noOutline: PropTypes.bool,
  promocode: PropTypes.string,
  testIdPrefix: PropTypes.string,
  buySku: PropTypes.string,
  trySku: PropTypes.string,
  tryCtaMessageId: PropTypes.string,
}

export default BuyTry
