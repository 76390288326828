import React from 'react'
import cx from 'classnames'

import BuyTry from '@/components/blocks/BuyTry'
import { Column, Section } from '@/components/layout'
import { Text, ButtonGroup } from '@/components/common'
import PricingCta from '@/components/PricingCta'
import TryFreeCta from '@/components/TryFreeCta'
import HomePageStats from '@/components/Pages/HomePage/HomePageStats'
import TestimonialSliderSlim from '@/components/blocks/TestimonialSliderSlim'
import HomePageFeatures from '@/components/Pages/HomePage/HomePageFeatures'
import styles from '@/components/Pages/HomePage/HomePage.module.scss'

const pageId = 'home'
const getMessageId = (id) => `pages.${pageId}.${id}`

const testimonials = [
  {
    text: 'It makes budgeting, tax preparation and documentation on my spending so easy. I have been a neat receipts user for over ten years and only once had a problem that the service dept. worked on quickly and efficiently.',
    author: 'Patricia M.',
  },
  {
    text: 'I am so thrilled learning more about NEAT. I\'ve used it for years and never knew it was such an Organizational BEAST! LOVE IT!',
    author: 'Douglas G.',
  },
  {
    text: 'Awesome to keep track of receipts.',
    author: 'Brian C.',
  },
  {
    text: 'Neat has cut my tax preparation from literally weeks, down to two days. Love it.',
    author: 'James F.',
  },
  {
    text: 'I have been using Neat for at least 7 years, and it\'s made tracking receipts and generating year end reports a breeze.',
    author: 'Joann D.',
  },
  {
    text: 'Makes end of the year filing so easy! No need to save receipts.',
    author: 'Michael K.',
  },
  {
    text: 'I meet with clients to do home closings every day. I tell them the benefits of using Neat.com. Most homeowners never think they may have a fire or flood. I have to remind them that paper or backup drives will be destroyed during both tragedies.',
    author: 'LaTonja A.',
  },
  {
    text: 'It pulls the information off the page, actually reads the text that\'s on there, and organizes it for you.',
    author: 'Josh Z.',
  },
  {
    text: 'The system is fantastic and eliminates many hours of filing and finding files.',
    author: 'Andy K.',
  },
]

export default function HomePage() {
  return (
    <>
      <Section className={styles.headerSection} wrapperClassName={styles.headerWrapper}>
        <Column className="w-full">
          <div className={styles.heroHolder}>
            <div className="max-w-700">
              <Text.H1 brandPrimary messageId={getMessageId('main.header.title')} normal size="medium" />
              <Text.H2 messageId={getMessageId('main.header.subtitle')} size="xlarge" />
              <BuyTry className={styles.headerBuyTry} justifyStart noOutline testIdPrefix="homepage" />
            </div>
          </div>
        </Column>
      </Section>
      <HomePageFeatures />
      <HomePageStats />
      <Section bgSecondary>
        <Text.H2 style={{ marginBottom: '30px' }}>Customers love Neat!</Text.H2>
        <Column wrap>
          <TestimonialSliderSlim fixedHeight="250" multiSlides slides={testimonials} />
        </Column>
      </Section>
      <Section className={styles.securitySection} wrapperClassName={styles.securityWrapper}>
        <div className={cx(styles.securityTextWrapper, 'max-w-600')}>
          <Text.H2 brandSecondary messageId={getMessageId('security.title')} normal />
          <Text.P messageId={getMessageId('security.p')} onTint />
        </div>
      </Section>
      <Section>
        <ButtonGroup>
          <PricingCta messageId="cta.seePlansAndPricing" size="xlarge" />
          <TryFreeCta />
        </ButtonGroup>
      </Section>
    </>
  )
}

HomePage.layoutProps = {
  pageId,
}
