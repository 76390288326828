import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './TestimonialSliderSlim.module.scss'

const TestimonialSliderSlim = ({
  slides = [],
  multiSlides = false,
  fixedHeight = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [slidesToShow, setSlidesToShow] = useState(1)

  // Function to get the number of slides to show based on screen size
  const getSlidesToShow = () => {
    if (!multiSlides) return 1
    if (window.innerWidth >= 1024) return 3 // Desktop (3 slides)
    if (window.innerWidth >= 768) return 2 // Tablet (2 slides)
    return 1
  }

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(getSlidesToShow())
    }

    handleResize()

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [multiSlides])

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (
      prevIndex === 0 ? slides.length - slidesToShow : (prevIndex - slidesToShow + slides.length) % slides.length))
  }

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + slidesToShow) % slides.length)
  }

  // Set up automatic slide advance (timeout logic)
  useEffect(() => {
    const handle = setTimeout(() => {
      goToNext()
    }, 10000)

    return () => clearTimeout(handle)
  }, [currentIndex]) // Remove slidesToShow from the dependency array

  const visibleSlides = []
  for (let i = 0; i < slidesToShow; i += 1) {
    visibleSlides.push(slides[(currentIndex + i) % slides.length])
  }

  return (
    <div className={cx(styles.sliderContainer, { [styles.multiSlides]: multiSlides })}>
      <div className={styles.testimonialWrapper} style={fixedHeight ? { height: `${fixedHeight}px` } : {}}>
        {visibleSlides.map((slide) => (
          <div key={slide.author} className={styles.testimonialItem}>
            <div className={styles.testimonialText}>
              <p>
                <span>“</span>{slide.text}<span>“</span>
              </p>
            </div>
            <div className={styles.author}>
              <h4>{slide.author}</h4>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.leftArrow} onClick={goToPrevious}>
          &#10094;
        </button>
        <button className={styles.rightArrow} onClick={goToNext}>
          &#10095;
        </button>
      </div>
    </div>
  )
}

TestimonialSliderSlim.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  multiSlides: PropTypes.bool,
  fixedHeight: PropTypes.string,
}

export default TestimonialSliderSlim
