import React from 'react'

import routes from '@/routes'
import { Link } from '@/components/common'

const PricingCta = (props) => (<Link
  messageId="cta.getStarted"
  size="large"
  to={routes.pricing}
  variant="primary"
  {...props}
/>)

export default PricingCta
