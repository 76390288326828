import React from 'react'
import PropTypes from 'prop-types'

import { Link } from '@/components/common'
import routes from '@/routes'

import styles from './BuyNowCta.module.scss'

const BuyNowCta = ({
  messageId = 'cta.buyNow',
  sku = 'neatyr',
  source = 'Checkout',
  promocode,
  variant = 'primary',
  messageProps,
  ...rest
}) => {
  const buyInfoString = JSON.stringify({ sku, source, promocode })

  return (
    <Link
      className={variant === 'success' ? '' : styles.link}
      data-buyinfo={buyInfoString}
      messageId={messageProps?.id ? undefined : messageId}
      messageProps={messageProps}
      size="xlarge"
      to={`${routes.signUp}?sku=${sku}${promocode ? `&couponCode=${promocode}` : ''}`}
      variant={variant}
      {...rest}
    />
  )
}

BuyNowCta.propTypes = {
  sku: PropTypes.string,
  source: PropTypes.string,
  promocode: PropTypes.string,
  messageId: PropTypes.string,
  variant: PropTypes.string,
  messageProps: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export default BuyNowCta
